import equipmentHPConstants from './equipmentHPConstants.js';

const QUARTS = 0.25;
const PINTS = 0.125;

export default {
  570317: {
    serialNumber: 'MK610167',
    assetNumber: '196356',
    calAmpNumber: '570317',
    fuelMultiplier: QUARTS,
    fuelConfig: equipmentHPConstants.cat982M,
  },
  570318: {
    serialNumber: 'MK610165',
    assetNumber: '196355',
    calAmpNumber: '570318',
    fuelMultiplier: QUARTS,
    fuelConfig: equipmentHPConstants.cat982M,
  },
  660056: {
    serialNumber: 'XDL00794',
    assetNumber: '227031',
    calAmpNumber: '660056',
    fuelMultiplier: QUARTS,
    fuelConfig: equipmentHPConstants.cat982M,
  },
  570306: {
    serialNumber: 'N/A',
    assetNumber: 'N/A',
    calAmpNumber: '570306',
    fuelMultiplier: QUARTS,
    fuelConfig: equipmentHPConstants.cat982M,
  },
  573455: {
    serialNumber: 'N/A',
    assetNumber: 'N/A',
    calAmpNumber: '573455',
    fuelMultiplier: QUARTS,
    fuelConfig: equipmentHPConstants.cat982M,
  },
  570316: {
    serialNumber: 'N/A',
    assetNumber: 'N/A',
    calAmpNumber: '570316',
    fuelMultiplier: QUARTS,
    fuelConfig: equipmentHPConstants.cat982M,
  },
  573252: {
    serialNumber: 'N/A',
    assetNumber: 'N/A',
    calAmpNumber: '573252',
    fuelMultiplier: PINTS,
    fuelConfig: equipmentHPConstants.cat982M,
  },
  641838: {
    serialNumber: 'N/A',
    assetNumber: 'N/A',
    calAmpNumber: 641838,
    fuelMultiplier: QUARTS,
    fuelConfig: equipmentHPConstants.cat982M,
  },
  660391: {
    serialNumber: 'N/A',
    assetNumber: 'N/A',
    calAmpNumber: 660391,
    fuelMultiplier: QUARTS,
    fuelConfig: equipmentHPConstants.cat982M,
  },
  642043: {
    serialNumber: '9980',
    assetNumber: 'N/A',
    calAmpNumber: 642043,
    fuelMultiplier: QUARTS,
    fuelConfig: equipmentHPConstants.cat982M,
  },
  641613: {
    serialNumber: '9980',
    assetNumber: 'N/A',
    calAmpNumber: 641613,
    fuelMultiplier: QUARTS,
    fuelConfig: equipmentHPConstants.cat982M,
  },
  659157: {
    serialNumber: 'N/A',
    assetNumber: 'Compass #1',
    calAmpNumber: 659157,
    fuelMultiplier: QUARTS,
    fuelConfig: equipmentHPConstants.cat982M,
  },
  659149: {
    serialNumber: 'N/A',
    assetNumber: 'Compass #1',
    calAmpNumber: 659149,
    fuelMultiplier: QUARTS,
    fuelConfig: equipmentHPConstants.cat982M,
  },
  641865: {
    serialNumber: 'N/A',
    assetNumber: 'N/A',
    calAmpNumber: 641865,
    fuelMultiplier: QUARTS,
    fuelConfig: equipmentHPConstants.cat982M,
  },
};
