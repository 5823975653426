const eventBus = new EventTarget();

/**
 * @typedef { 'userLoggedIn' | 'dataLoaded' | 'loading' | 'tableMouseOver' | 'tableMouseOut' |
 * 'tableMapDataUpdated' | 'settingsUpdated' | 'eventChartHover' | 'eventChartHoverOut' |
 * 'submitBtnClicked' } BusEventTypes
 */

// eslint-disable-next-line jsdoc/no-undefined-types
/** @type {{ [K in BusEventTypes]: K }} */
const BUS_EVENT_TYPES = {
  userLoggedIn: 'userLoggedIn',
  submitBtnClicked: 'submitBtnClicked',
  dataLoaded: 'dataLoaded',
  loading: 'loading',
  tableMouseOver: 'tableMouseOver',
  tableMouseOut: 'tableMouseOut',
  tableMapDataUpdated: 'tableMapDataUpdated',
  settingsUpdated: 'settingsUpdated',
  eventChartHover: 'eventChartHover',
  eventChartHoverOut: 'eventChartHoverOut',
};

/**
 * @typedef {object} eventDetail
 * @property {object} detail - The event detail object
 */
const eventDetail = { detail: {} };

/**
 * @typedef {object} submitBtnClickedDetail
 * @property {number} selectedDeviceId - The selected device id
 * @property {Date} selectedDate - The selected date
 */
const submitBtnClickedDetail = {
  selectedDeviceId: 0,
  selectedDate: new Date(),
};
/**
 *
 * @param {keyof BUS_EVENT_TYPES} eventType - The event type to subscribe to
 * @param {Function} callback - The callback function to execute when the event is triggered
 */
function subscribe(eventType, callback) {
  eventBus.addEventListener(eventType, callback);
}

/**
 *
 * @param {keyof typeof BUS_EVENT_TYPES} eventType - The event type to subscribe to
 * @param {*} data - Event context data
 */
function publish(eventType, data) {
  eventBus.dispatchEvent(new CustomEvent(eventType, { detail: data }));
}

export {
  subscribe, publish, BUS_EVENT_TYPES, submitBtnClickedDetail, eventDetail,
};
