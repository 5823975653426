import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase/firebaseInit.js';
import { setUserRoles } from './userRole.js';
import { BUS_EVENT_TYPES, publish } from './eventBus.js';

/**
 * Function to redirect to the login page
 */
function redirectToLogin() {
  if (!navigator.userAgent.includes('jsdom')) { window.location.href = `/login.html?redirect=${window.location.pathname}`; }
}

onAuthStateChanged(auth, (user) => {
  if (user) {
    if (document.getElementById('user-email')) document.getElementById('user-email').textContent = user.email;
    // Get the user's ID token
    user.getIdTokenResult()
      .then((idTokenResult) => {
        setUserRoles(idTokenResult.claims);

        publish(BUS_EVENT_TYPES.userLoggedIn);
      })
      .catch((error) => {
        console.error('Error getting ID token:', error);
      });

    // ...
  } else {
    // User is signed out
    // ...
    console.log('User is signed out, redirecting to login page.');
    redirectToLogin();
  }
});

/**
 *
 */
function logout() {
  auth.signOut().then(() => {
    console.log('User signed out.');
  }).catch((error) => {
    console.error('Error signing out: ', error);
  });
}
// call logout when id="logout" is clicked if it exists
if (document.getElementById('logout')) { document.getElementById('logout').addEventListener('click', logout); }

export { redirectToLogin, logout };
