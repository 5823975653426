import axios from 'axios';
import { getIdToken, getAuth } from 'firebase/auth';
import AvlEvent from './avlevent.js';
import deviceList from '../helpers/deviceList.js';

/**
 *
 * @param {Date} date - Date to convert
 * @returns {{startDate: string, endDate: string}} - Object with start and end date
 * in ISO 8601 format
 */
function convertDateToDateRangeUTC(date) {
  // assert valid date
  if (Number.isNaN(Date.parse(date))) {
    throw new Error('Invalid date');
  }
  const startDate = new Date(date);
  // Get timezone offset in minutes and convert it to hours
  const timezoneOffsetHours = startDate.getTimezoneOffset() / 60;
  // Adjust the start date by the timezone offset
  startDate.setUTCHours(timezoneOffsetHours);
  const endDate = new Date(date);
  // Subtract 23 hours, 59 minutes, and 59 seconds
  endDate.setUTCHours(startDate.getUTCHours() + 23);
  endDate.setUTCMinutes(startDate.getUTCMinutes() + 59);
  endDate.setUTCSeconds(startDate.getUTCSeconds() + 59);
  return { startDate: startDate.toISOString(), endDate: endDate.toISOString() };
}

/**
 * Get the id token from the current user and make an authenticated API call
 * @param {import('axios').AxiosRequestConfig} options - axios request options
 * @returns {Promise<import('axios').AxiosResponse<any>>} - A Promise that resolves
 * to an AxiosResponse object.
 */
async function makeAuthenticatedApiCall(options) {
  try {
    const auth = getAuth();
    const { currentUser } = auth;
    const idToken = await getIdToken(currentUser);
    const updatedOptions = {
      ...options,
      headers: {
        ...options.headers,
        Authorization: `${idToken}`,
      },
    };
    return await axios.request(updatedOptions);
  } catch (error) {
    console.error('Error while making authenticated API call', error);
    throw error;
  }
}

/**
 *
 * @param {number} deviceId - Device ID
 * @param {Date} date - Date to fetch data for
 * @param {number} pageNumber - Page number
 * @param {string} applicationKey - application key
 * @returns {Promise<{ events:[], isLastPage:boolean  }>} - Events
 */
async function fetchDataFromApi(
  deviceId,
  date,
  pageNumber,
  applicationKey = window.applicationKey,
) {
  const { startDate, endDate } = convertDateToDateRangeUTC(date);

  const options = {
    method: 'GET',
    url: `/api/results/events/${deviceId}/?startDate=${startDate}&endDate=${endDate}&pg=${pageNumber}`,
    headers: {
      accept: '*/*',
      'content-type': 'application/json',
      'calamp-services-app': applicationKey,
    },

  };
  const response = await makeAuthenticatedApiCall(options);

  const events = response.data.response.results;
  return { events, isLastPage: response.data.response.last };
}

/**
 *
 * @param {string} deviceId - Device ID
 * @param {string} date - date in 'YYYY-MM-DD' format like 2023-10-31
 * @param {number} pageNumber - page number
 * @param {Array} accumulatedResults - accumulated results
 * @returns {Promise<import('../../types/models').AvlEvent[]>} - A Promise
 * that resolves to an array of AvlEvent instances or a string in case of error.
 */
async function getAllEvents(deviceId, date, pageNumber = 1, accumulatedResults = []) {
  try {
    const { events, isLastPage } = await fetchDataFromApi(deviceId, date, pageNumber);
    accumulatedResults.push(...events);

    if (!isLastPage && pageNumber < 5) {
      await getAllEvents(deviceId, date, pageNumber + 1, accumulatedResults);
    }
    const avlEvents = accumulatedResults.map((element) => new AvlEvent(element.avlEvent));

    return avlEvents;
  } catch (error) {
    if (error.response && error.response.status >= 400) {
      // window.location.href = `/login.html?redirect=${window.location.pathname}`;
      // TODO: Show error
    }
    throw error;
  }
}

/**
 * @typedef {import("../../types/models").RegenHourFuel} RegenDuration
 * @param {number} deviceId - Device ID
 * @returns {Promise<RegenDuration[]>} - Fuel regen data
 */
async function getFuelRegen(deviceId) {
  try {
    const axiosOptions = {
      method: 'GET',
      url: `/api/fuelregen/${deviceId}`,
    };
    const response = await makeAuthenticatedApiCall(axiosOptions);
    const { fuelMultiplier } = deviceList[deviceId];
    response.data.forEach((regen) => {
      // eslint-disable-next-line no-param-reassign
      regen.fuel_used = regen.fuel_event_count * fuelMultiplier;
    });
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    return null;
  }
}

/**
 * @typedef {import("../../types/deviceDetails").DeviceDetails} DeviceDetails
 */

/** @type {DeviceDetails[]} */
const deviceDetails = [];

/**
 * @returns {Promise<DeviceDetails[]>} - Array of device details
 */
async function getDeviceDetails() {
  const options = {
    url: '/api/devices',
    method: 'GET',
  };
  if (deviceDetails.length > 0) return deviceDetails;

  try {
    const response = await makeAuthenticatedApiCall(options);
    // convert response.data to deviceDetails
    response.data.forEach((device) => {
      deviceDetails.push({
        calampDeviceId: String(device.calampDeviceId),
        assetNumber: device.assetNumber,
        serialNumber: device.serialNumber,
        locationName: device.locationName,
        fuelMultiplier: device.fuelMultiplier,
        id: device.id,
      });
    });
    return deviceDetails;
  } catch (error) {
    console.error('Error fetching device details', error);
    throw error;
  }
}

export {
  getAllEvents, fetchDataFromApi, getFuelRegen, convertDateToDateRangeUTC,
  makeAuthenticatedApiCall, getDeviceDetails,
};
