/**
 * Class representing an AVL Event.
 */
export default class AvlEvent {
  /**
   * Create a new AVL Event.
   * @param {object} eventData - The event data.
   * @param {string} eventData.eventType - The type of the event.
   * @param {Date|string} eventData.eventTime - The time of the event.
   * @param {number} eventData.latitude - The latitude of the event.
   * @param {number} eventData.longitude - The longitude of the event.
   * @param {number} eventData.eventid - The ID of the event.
   * @param {number} eventData.deviceId - The ID of the device.
   * @param {string} eventData.messageUuid - The UUID of the message.
   * @param {boolean} [eventData.visible] - Whether the event is visible.
   */
  constructor(eventData) {
    this.eventType = eventData.eventType;
    this.eventTime = new Date(eventData.eventTime);
    this.latitude = eventData.latitude;
    this.longitude = eventData.longitude;
    this.eventid = eventData.eventid;
    this.deviceId = eventData.deviceId;
    this.messageUuid = eventData.messageUuid;
    // set visible true by default
    this.visible = eventData.visible || true;
  }

  /**
   * Get the event type.
   * @returns {string} The event type.
   */
  getEventType() {
    return this.eventType;
  }

  /**
   * Get the event time.
   * @returns {Date} The event time.
   */
  getEventTime() {
    return this.eventTime;
  }
}
