let loggedinUserRole = null;
/**
 *
 * @param {import("firebase/auth").ParsedToken} userDetails - userDetails
 */
function setUserRoles(userDetails) {
  if (!userDetails) throw new Error('Roles are required');
  loggedinUserRole = userDetails.role;
}

/**
 *
 * @returns {Array} roles
 */
function getUserRoles() {
  return loggedinUserRole;
}

/**
 * Check if user is admin
 * @returns {boolean} isAdmin
 */
function isAdmin() {
  return loggedinUserRole === 'MIRENCO_ADMIN';
}

export { getUserRoles, setUserRoles, isAdmin };
